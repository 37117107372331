import React, { Component } from "react";
import PageHelmet from "../Components/Utils/PageHelmet";
import { fetchTipologie } from "../redux/actions/tipologiaActions";
import { getMateriePrimeByDescrizione } from "../redux/actions/materiaPrimaActions";
import {
  updatePietanza,
  fetchPietanzeByTipologia,
  addPietanza,
  fetchPietanzePaging,
  resetForm,
  getPietanza,
  deletePietanza,
  setPietanzaImage,
  deletePietanzaImage,
} from "../redux/actions/pietanzaActions";
import { connect } from "react-redux";
import { FastFoodOutline } from "react-ionicons-v5";
import LoadingData from "../Components/Utils/Datatable/LoadingData";
import DataTable from "react-data-table-component";
import memoize from "memoize-one";
import { FiChevronDown, FiPlus, FiTrash, FiEdit2 } from "react-icons/fi";
import NoDataComponent from "../Components/Utils/Datatable/NoDataComponent";
import AnimateHeight from "react-animate-height";
import PietanzeForm from "../Components/Pietanze/PietanzeForm";
import { store } from "react-notifications-component";
import { confirmAlert } from "react-confirm-alert";
import alertOptions from "../Components/Alert/alertOptions";
import PietanzeTableDetails from "../Components/Pietanze/PietanzeTableDetails";
import ImageUploader from "react-images-upload";
import { GrClose } from "react-icons/gr";
import { checkFile } from "../Components/Business/checkFile";
import { server } from "../config/server";
import { AiFillEye } from "react-icons/ai";
// import { GiWheat } from "react-icons/gi";
// import { CgClose } from "react-icons/cg";

const columns = memoize((actionHandler) => [
  {
    width: "75px",
    sortable: false,
    cell: (row) => (
      <div
        className={`pietanza-overlay-container ${
          row.extension ? "has-image" : ""
        }`}
      >
        <div
          className="pietanza-image"
          style={{
            backgroundImage: !row.extension
              ? "url(/assets/images/emptyp.png)"
              : `url(${server}downloadPietanzaImage?param=${row.id}.${
                  row.extension
                }#${new Date().getTime()})`,
          }}
          onClick={() => actionHandler("setImage", row.id)}
        ></div>
        {row.extension && (
          <div className="overlay-pietanza">
            <div
              className="remove"
              onClick={() => actionHandler("removeImage", row.id)}
            >
              <FiTrash />
            </div>
            <div
              className="view"
              onClick={() => actionHandler("openImage", row)}
            >
              <AiFillEye />
            </div>
          </div>
        )}
      </div>
    ),
  },
  {
    name: "Descrizione",
    selector: "descrizione",
    sortable: true,
  },
  {
    name: "Costo €",
    selector: "costoTotale",
    sortable: false,
    width: "100px",
    right: true,
    cell: (row) => (
      <div className="primary" style={{ fontWeight: "700" }}>
        {row.costoTotale.toFixed(3)}
      </div>
    ),
  },
  {
    name: "Vendita €",
    selector: "prezzoTotale",
    sortable: false,
    width: "100px",
    right: true,
    cell: (row) => (
      <div className="secondary" style={{ fontWeight: "700" }}>
        {row.prezzoTotale.toFixed(3)}
      </div>
    ),
  },
  {
    sortable: false,
    width: "100px",
    right: true,
    cell: (row) => (
      <div className="d-flex">
        <button
          className="btn btn-circle btn-sm btn-table warning"
          onClick={() => actionHandler("update", row.id)}
        >
          <FiEdit2 />
        </button>
        <button
          className="btn btn-circle btn-sm ml-1 btn-table danger"
          onClick={() => actionHandler("delete", row.id)}
        >
          <FiTrash />
        </button>
      </div>
    ),
  },
]);

class Pietanze extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      tipologia: 0,
      sortBy: "descrizione",
      sortDirection: "ASC",
      descrizione: "",
      pageNumber: 1,
      pageSize: 25,
      formOpened: false,
      isDeleting: false,
      materiePrime: [],
      overlayOpened: false,
      pietanzaSelected: null,
      lightbox: false,
      image: null,
      extension: null,
    };

    this.pietanzeForm = React.createRef();
    this.blurActiveElement = this.blurActiveElement.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keypress", (e) => {
      if (document.activeElement.tagName !== "TEXTAREA") {
        this.blurActiveElement(e);
      }
    });
    this.getTipologie();
    window.addEventListener("resize", () => {
      const width = window.innerWidth;
      if (width <= 1024) {
        const el = document.getElementById("tipologie_menu");
        if (el) el.classList.remove("panel-pietanze-collapsed-on");
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", (e) => this.blurActiveElement(e));
  }

  handleSubmit = (data) => {
    const { pietanza } = this.props;

    const descrizione = data.descrizione;
    const info = data.info;
    const materieprime = this.state.materiePrime.map((m) => m.id);
    const tipologia = this.state.tipologia;
    const bodyFormData = new FormData();
    materieprime.forEach((item) => {
      bodyFormData.append("materieprime[]", item);
    });
    bodyFormData.append("descrizione", descrizione);
    bodyFormData.append("info", info);
    bodyFormData.append("tipologia", tipologia);

    if (!pietanza.hasOwnProperty("id")) {
      return this.props
        .addPietanza(bodyFormData)
        .then((res) => {
          this.actionsCallback();
          this.setState({ materieprime: [] });
          store.addNotification({
            ...alertOptions,
            message: "Operazione effettuata",
            type: "success",
          });
        })
        .catch(() => {
          this.actionsCallback();
          store.addNotification({
            ...alertOptions,
            message: "Impossibile completare l'operazione",
            type: "danger",
          });
        });
    }
    bodyFormData.append("id", pietanza.id);
    return this.props
      .updatePietanza(bodyFormData)
      .then(() => {
        this.actionsCallback();
        this.setState({ materieprime: [] });
        store.addNotification({
          ...alertOptions,
          message: "Operazione effettuata",
          type: "success",
        });
      })
      .catch(() => {
        this.actionsCallback();
        store.addNotification({
          ...alertOptions,
          message: "Impossibile completare l'operazione",
          type: "danger",
        });
      });
  };

  blurActiveElement = (e) => {
    if (e.keyCode === 13) {
      document.activeElement.blur();
    }
  };

  getTipologie = () => {
    this.props.fetchTipologie().then((res) => {
      if (res.value.data.response.length === 0) return;
      const { activeIndex } = this.state;
      const id = res.value.data.response[activeIndex].id;
      this.setState({ tipologia: id });
      this.getPietanze();
    });
  };

  getPietanze = () => {
    const { sortBy, sortDirection, descrizione, pageNumber, pageSize } =
      this.state;
    this.props.fetchPietanzePaging(
      this.state.tipologia,
      sortBy,
      sortDirection,
      descrizione,
      pageNumber - 1,
      pageSize
    );
  };

  togglePanel = () => {
    const el = document.getElementById("tipologie_menu");
    if (el.classList.contains("panel-pietanze-collapsed-on")) {
      el.classList.remove("panel-pietanze-collapsed-on");
    } else {
      el.classList.add("panel-pietanze-collapsed-on");
    }
  };

  setActive = (index, tipologia) => {
    this.togglePanel();
    this.closeForm();
    this.setState(
      {
        activeIndex: index,
        tipologia: tipologia,
      },
      () => {
        this.getTipologie();
      }
    );
  };

  handlePageChange = (page, totalRows) => {
    this.setState(
      {
        pageNumber: page,
      },
      () => this.getPietanze()
    );
  };

  handlePerRowsChange = (currentRowsPerPage, currentPage) => {
    this.setState(
      {
        pageSize: currentRowsPerPage,
      },
      () => this.getPietanze()
    );
  };

  onSort = (column, sortDirection) => {
    const { selector } = column;
    this.setState(
      {
        sortBy: selector,
        sortDirection: sortDirection.toUpperCase(),
      },
      () => this.getPietanze()
    );
  };

  onFilter = ({ target }) => {
    this.setState(
      {
        pageNumber: 1,
        descrizione: target.value,
      },
      () => this.getPietanze()
    );
  };

  actionHandler = (action, data) => {
    switch (action) {
      case "update":
        this.openForm();
        this.onGetPietanza(data);
        break;
      case "delete":
        this.onDelete(data);
        break;
      case "setImage":
        this.addImage(data);
        break;
      case "removeImage":
        this.removeImage(data);
        break;
      case "openImage":
        this.openImage(data);
        break;
      default:
        break;
    }
  };

  onGetPietanza = (data) => {
    this.props.getPietanza(data).then((res) => {
      const pietanza = res.value.data.response;
      this.setState({
        materiePrime: pietanza.materieprime.sort((a, b) =>
          a.descrizione.localeCompare(b.descrizione)
        ),
      });
    });
  };

  subActions = () => (
    <div className="ft-table-search-container  mt-3">
      <input
        type="search"
        className="ft-table-search ft-form-control form-control datatable-search"
        name="search"
        value={this.state.descrizione}
        role="search"
        placeholder="Cerca..."
        onChange={this.onFilter}
      />
      <button
        className="ft-btn-add-element btn ft-btn-primary btn-circle"
        onClick={this.openForm}
        disabled={this.state.formOpened}
      >
        <FiPlus />
      </button>
    </div>
  );

  openForm = () => {
    window.scrollTo(0, 0);
    this.setState({ formOpened: true });
    setTimeout(() => {
      document.getElementsByName("descrizione")[0].focus();
    }, 500);
  };

  closeForm = () => {
    this.setState({ formOpened: false, materiePrime: [] });
    this.pietanzeForm.current.reset();
    this.props.resetForm();
  };

  actionsCallback = () => {
    this.getPietanze();
    this.closeForm();
  };

  onDelete = (data) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Si",
          onClick: () => {
            this.props
              .deletePietanza(data)
              .then(() => {
                this.actionsCallback();
                store.addNotification({
                  ...alertOptions,
                  message: "Operazione effettuata",
                  type: "success",
                });
              })
              .catch(() => {
                store.addNotification({
                  ...alertOptions,
                  message: "Impossibile completare l'operazione",
                  type: "danger",
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  renderMateriePrime = (inputValue, callback) => {
    if (!inputValue || inputValue.length < 3) {
      return callback([]);
    }
    this.props.getMateriePrimeByDescrizione(inputValue).then((res) => {
      return callback(res.value.data.response);
    });
  };

  addMateriaPrima = (input, value) => {
    input.onChange(value);
    const { materiePrime } = this.state;
    if (value) {
      if (!materiePrime.some((e) => e.id === value.id)) {
        let newArray = [...materiePrime, value];
        newArray.sort((a, b) => a.descrizione.localeCompare(b.descrizione));
        this.setState({ materiePrime: newArray });
      } else {
        store.addNotification({
          ...alertOptions,
          message: "Materia prima già inserita",
          type: "warning",
        });
      }
      input.onChange(null);
    }
  };

  deleteMateriaPrima = (materiaPrima) => {
    const { materiePrime } = this.state;
    const newArray = materiePrime.filter((mp) => mp.id !== materiaPrima.id);
    this.setState({ materiePrime: newArray });
  };

  closeOverlay = () => {
    this.setState({
      overlayOpened: false,
      pietanzaSelected: null,
    });
  };

  addImage = (id) => {
    this.openOverlay(id);
  };

  openOverlay = (id) => {
    this.setState({
      overlayOpened: true,
      pietanzaSelected: id,
    });
  };

  onDrop(picture) {
    if (!picture) return;
    const { pietanzaSelected } = this.state;
    if (pietanzaSelected) {
      const file = picture[0];
      const splitName = file.name.split(".");
      splitName.pop();
      if (checkFile(file)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const image = {
            base64: reader.result.split(",")[1],
            type: file.type,
            pietanza: pietanzaSelected,
          };
          //SEND IMAGE
          this.props
            .setPietanzaImage(image)
            .then(() => {
              this.closeOverlay();
              this.getPietanze();
              store.addNotification({
                ...alertOptions,
                message: "Operazione effettuata",
                type: "success",
              });
            })
            .catch(() => {
              store.addNotification({
                ...alertOptions,
                message: "Impossibile completare l'operazione",
                type: "danger",
              });
            });
        };
        reader.onerror = (error) => {
          store.addNotification({
            ...alertOptions,
            message: "Impossibile completare l'operazione",
            type: "danger",
          });
        };
      }
    }
  }

  removeImage = (id) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'immagine?",
      buttons: [
        {
          label: "Si",
          onClick: () => {
            this.props
              .deletePietanzaImage(id)
              .then(() => {
                this.getPietanze();
                store.addNotification({
                  ...alertOptions,
                  message: "Operazione effettuata",
                  type: "success",
                });
              })
              .catch(() => {
                store.addNotification({
                  ...alertOptions,
                  message: "Impossibile completare l'operazione",
                  type: "danger",
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  openImage = (data) => {
    this.setState({
      lightbox: true,
      image: data.id,
      extension: data.extension,
    });
  };

  closeImage = () => {
    this.setState({ lightbox: false, image: null, extension: null });
  };

  render() {
    const {
      tipologie,
      pietanze,
      isLoadingPietanze,
      isLoadingPietanza,
      isUpdatingPietanza,
      pietanza,
      isLoadingMateriePrime,
    } = this.props;
    const {
      activeIndex,
      pageSize,
      sortDirection,
      pageNumber,
      formOpened,
      isDeleting,
      materiePrime,
      overlayOpened,
      lightbox,
      image,
      extension,
    } = this.state;

    return (
      <>
        <PageHelmet pageTitle="Pietanze" />
        <div
          className={`pietanza-lightbox ${lightbox ? "isOpen" : ""}`}
          onClick={this.closeImage}
        >
          <div className="close-lightbox" onClick={this.closeImage}>
            <GrClose />
          </div>
          {image && extension && (
            <img
              src={`${server}downloadPietanzaImage?param=${image}.${extension}#${new Date().getTime()}`}
              alt={image}
              className="lightbox-image"
            />
          )}
        </div>
        <div className={`overlay-image ${overlayOpened ? "isOpened" : ""}`}>
          <GrClose className="close-overlay" onClick={this.closeOverlay} />
          <ImageUploader
            withIcon={true}
            onChange={this.onDrop}
            imgExtension={[".jpeg", ".jpg", ".gif", ".png", ".gif"]}
            maxFileSize={2097152}
            buttonText="Scegli immagine"
            label="Dimensione massima: 2Mb, accetta: jpg, jpeg, png"
            buttonStyles={{ backgroundColor: "#f0555d" }}
            singleImage
          />
        </div>
        <div
          className={`${
            tipologie.response && tipologie.response.length > 0
              ? "pietanze_container"
              : ""
          }`}
        >
          <div className="ft-page-title">
            <div className="d-flex">
              {tipologie.response &&
                tipologie.response.length > 0 &&
                tipologie.response[activeIndex].descrizione}
            </div>
          </div>
        </div>
        {tipologie.response && tipologie.response.length > 0 && (
          <div className="tipologie_menu" id="tipologie_menu">
            <div className="cover"></div>
            <div className="toggle_panel" onClick={() => this.togglePanel()}>
              <FastFoodOutline />
            </div>
            <ul>
              {tipologie.response.map((tipologia, index) => {
                return (
                  <li
                    key={index}
                    className={`${index === activeIndex ? "active" : ""}`}
                    onClick={() => this.setActive(index, tipologia.id)}
                  >
                    {tipologia.descrizione}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div
          className={`${
            tipologie.response && tipologie.response.length > 0
              ? "pietanze_container"
              : ""
          }`}
        >
          <AnimateHeight duration={350} height={formOpened ? "auto" : 0}>
            <div className="row mb-4">
              <div className="col col-12">
                <div className="card ft-box">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h6>
                        {pietanza.hasOwnProperty("id")
                          ? "Modifica"
                          : "Aggiungi"}{" "}
                        pietanza
                      </h6>
                      <button
                        className="ft-btn-close-form btn btn-sm ft-btn-warning"
                        onClick={this.closeForm}
                      >
                        Chiudi
                      </button>
                    </div>
                    {(isLoadingPietanza ||
                      isUpdatingPietanza ||
                      isLoadingMateriePrime) && <LoadingData />}
                    <PietanzeForm
                      deleteMateriaPrima={this.deleteMateriaPrima}
                      materiePrime={materiePrime}
                      addMateriaPrima={this.addMateriaPrima}
                      onSubmit={this.handleSubmit}
                      ref={this.pietanzeForm}
                      initialValues={pietanza}
                      renderMateriePrime={this.renderMateriePrime}
                    />
                  </div>
                </div>
              </div>
            </div>
          </AnimateHeight>
          <div className="row">
            <div className="col col-12">
              <div className="card ft-box">
                <div
                  className={`card-body ${
                    tipologie.response && tipologie.response.length > 0
                      ? "ft-data-table-body"
                      : ""
                  }`}
                >
                  <div className="row">
                    <div className="col col-12">
                      {tipologie.response && tipologie.response.length > 0 ? (
                        <div
                          className="card ft-box"
                          style={{ boxShadow: "none" }}
                        >
                          <div className="card-body ft-data-table-body">
                            {(isLoadingPietanze || isLoadingMateriePrime) && (
                              <LoadingData />
                            )}
                            <DataTable
                              className="ft-table"
                              columns={columns(this.actionHandler, isDeleting)}
                              defaultSortField="nome"
                              defaultSortAsc={
                                sortDirection === "asc" ? true : false
                              }
                              data={pietanze.content}
                              paginationTotalRows={pietanze.totalElements}
                              noHeader
                              allowOverflow
                              onSort={this.onSort}
                              sortIcon={<FiChevronDown />}
                              sortServer
                              subHeader
                              progressPending={false}
                              pagination
                              paginationServer
                              paginationRowsPerPageOptions={[
                                1, 2, 10, 25, 50, 100,
                              ]}
                              paginationPerPage={pageSize}
                              paginationDefaultPage={pageNumber}
                              onChangePage={this.handlePageChange}
                              onChangeRowsPerPage={this.handlePerRowsChange}
                              paginationComponentOptions={{
                                rowsPerPageText: "Elementi per pagina",
                                rangeSeparatorText: "di",
                              }}
                              subHeaderComponent={this.subActions()}
                              noDataComponent={<NoDataComponent />}
                              expandableRows
                              expandableRowsComponent={<PietanzeTableDetails />}
                              //   expandableIcon={{
                              //     collapsed: <GiWheat className="primary" />,
                              //     expanded: (
                              //       <CgClose
                              //         className="primary"
                              //         style={{ height: "47px" }}
                              //       />
                              //     ),
                              //   }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="card ft-box"
                          style={{ boxShadow: "none" }}
                        >
                          <div className="card-body">
                            Non hai inserito nessuna tipologia
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tipologie: state.tipologie.tipologie,
    isLoadingTipologie: state.tipologie.isLoadingTipologie,
    pietanze: state.pietanze.pietanze,
    isLoadingPietanze: state.pietanze.isLoadingPietanze,
    pietanza: state.pietanze.pietanza,
    isLoadingPietanza: state.pietanze.isLoadingPietanza,
    isUpdatingPietanza: state.pietanze.isUpdatingPietanza,
  };
};

export default connect(mapStateToProps, {
  updatePietanza,
  getMateriePrimeByDescrizione,
  fetchTipologie,
  fetchPietanzeByTipologia,
  addPietanza,
  fetchPietanzePaging,
  resetForm,
  getPietanza,
  deletePietanza,
  setPietanzaImage,
  deletePietanzaImage,
})(Pietanze);
