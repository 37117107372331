import axios from "axios";

const spring = axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? "http://localhost:8080/"
      : "https://gestionemenu.caltechvps.it/api/",
});

spring.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("jwt");
    if (token) {
      config.headers["jwt"] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

spring.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.error &&
      (error.response.data.session === false ||
        error.response.data.session === "false")
    ) {
      localStorage.removeItem("jwt"); // <-- add your var
      window.location = "/"; // <-- add your path
    }
    // else if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
    //   toastMessage(error.response.data.error.message, 1);
    // }
    else if (error.response.status === 504) {
      localStorage.removeItem("jwt"); // <-- add your var
      window.location = "/"; // <-- add your path
    } else return Promise.reject(error);
  }
);

export default spring;
